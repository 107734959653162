import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject } from '@angular/core';

import { UserStore } from '../store/user-store';

export const hasPermissionGuard: (
  route: ActivatedRouteSnapshot
) => Promise<boolean> = async (route: ActivatedRouteSnapshot) => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  await userStore.loadPermissionsAndUserData();
  if (route.data?.['permission']) {
    const hasPermission = userStore.hasPermission(route.data?.['permission']);
    if (!hasPermission) {
      router.navigate(['/access-denied']);
      return false;
    }
  }
  return true;
};
