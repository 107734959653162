import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { firstValueFrom } from 'rxjs';
import { Fee } from '../../modules/sales/models/fee';
import { FeesService } from '../../modules/sales/services/fees.service';
import { FeeNegotiation } from '../models/sales';
export interface SalesState {
  minimumFees: FeeNegotiation;
}
const initialState: SalesState = {
  minimumFees: {
    feeSaas: 0,
    feeConversion: 0,
    feeCollection: 0,
    feeSpread: 0,
    feePayout: 0,
  },
};

export const SalesStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, feesService = inject(FeesService)) => ({
    loadFees: async () => {
      const fees: Fee[] = await firstValueFrom(feesService.getAllFees());
      if (fees?.length) {
        const filteredFees = fees.filter(fee => fee.type === 'minimum');
        const feeValues = filteredFees.reduce(
          (acc, fee) => {
            switch (fee.name) {
              case 'fee_saas':
                acc.feeSaas = fee.value;
                break;
              case 'fee_conversion':
                acc.feeConversion = fee.value;
                break;
              case 'fee_collection':
                acc.feeCollection = fee.value;
                break;
              case 'fee_spread':
                acc.feeSpread = fee.value;
                break;
              case 'fee_payout':
                acc.feePayout = fee.value;
                break;
            }
            return acc;
          },
          {
            feeSaas: 0,
            feeConversion: 0,
            feePayout: 0,
            feeCollection: 0,
            feeSpread: 0,
          }
        );
        patchState(store, {
          minimumFees: feeValues,
        });
      }
    },
    resetUserData: async () => {
      patchState(store, initialState);
    },
  }))
);
