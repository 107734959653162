import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Fee } from '../models/fee';

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  private http = inject(HttpClient);

  getAllFees(): Observable<Fee[]> {
    return this.http.get<Fee[]>(`${environment.supraApi}/v1/sales/fees`);
  }

  updateFee(id: string, value: number): Observable<Fee> {
    return this.http.patch<Fee>(`${environment.supraApi}/v1/sales/fees/${id}`, {
      value,
    });
  }
}
